import React from 'react'
import Tilt from 'react-parallax-tilt'
import { motion } from 'framer-motion'
import SectionWrapper from './SectionWrapper'
import { skills } from '../const'
import { textVariant, fadeIn } from '../utils/motion'

const SkillCard = (props) => {
    const { index, title, icon } = props

    return (
        <Tilt className='xs:w-[250px] w-full'>
            <motion.div
                variants={fadeIn('right', 'spring', 0.5 * index, 0.75)}
                className='w-full green-pink-gradient p-[2px] rounded-[20px] shadow-card'
            >
                <div
                    options={{
                        max: 45,
                        scale: 1,
                        speed: 450,
                    }}
                    className='bg-tertiary rounded-[20px] py-5 px-12 min-h-[280px] flex justify-evenly items-center flex-col'
                >
                    <img src={icon} alt={title} className='w-16 h-16 object-contain' />
                    <h3 className='text-white text-[20px] font-bold text-center'>{title}</h3>
                </div>
            </motion.div>
        </Tilt>
    )
}

const About = () => {
    return (
        <>
            <motion.div variants={textVariant()}>
                <p className='sm:text-[18px] text-[14px] text-secondary uppercase tracking-wider'>
                    Introduction
                </p>
                <h2 className='text-pink md:text-[60px] sm:text-[50px] xs:text-[40px] text-[30px]'>
                    {' '}
                    Overview.{' '}
                </h2>
            </motion.div>
            <div className='mt-20 flex flex-wrap gap-10 justify-center'>
                {skills.map((skill, index) => (
                    <SkillCard key={skill.title} index={index} {...skill} />
                ))}
            </div>
        </>
    )
}

export default SectionWrapper(About, 'about')
