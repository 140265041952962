import React from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import { motion } from 'framer-motion'
import 'react-vertical-timeline-component/style.min.css'

import { experiences } from '../const'
import SectionWrapper from './SectionWrapper'
import { textVariant } from '../utils/motion'

const ExperienceCard = (props) => {
    const { experience } = props

    return (
        <VerticalTimelineElement
            contentStyle={{
                background: '#1d1836',
                color: '#fff',
            }}
            contentArrowStyle={{ borderRight: '7px solid  #232631' }}
            date={experience.date}
            iconStyle={{ background: experience.iconBg }}
            icon={
                <div className='flex justify-center items-center w-full h-full'>
                    <img
                        src={experience.icon}
                        alt={experience.company_name}
                        className='w-[80%] h-[80%] object-contain'
                    />
                </div>
            }
        >
            <div>
                <h3 className='text-white text-[24px] font-bold'>{experience.title}</h3>
                <p className='text-white text-[16ox] font-semibold' style={{ margin: 0 }}>
                    {experience.company_name}
                </p>
            </div>
            <ul className='mt-5 list-disc ml-5 space-y-2'>
                {experience.points.map((point, index) => (
                    <li
                        key={`experience-point-${index}`}
                        className='text-secondary-100 text-[14px] pl-1 tracking-wider'
                    >
                        {point}
                    </li>
                ))}
            </ul>
        </VerticalTimelineElement>
    )
}

const Experience = () => {
    return (
        <>
            <motion.div variants={textVariant()}>
                <p className='sm:text-[18px] text-[14px] text-secondary uppercase tracking-wider'>
                    What I have done so far
                </p>
                <h2 className='text-pink md:text-[60px] sm:text-[50px] xs:text-[40px] text-[30px]'>
                    {' '}
                    Work Experience.{' '}
                </h2>
            </motion.div>
            <div className='mt-20 flex flex-col'>
                <VerticalTimeline>
                    {experiences.map((experience, index) => (
                        <ExperienceCard key={index} experience={experience} />
                    ))}
                </VerticalTimeline>
            </div>
        </>
    )
}

export default SectionWrapper(Experience, 'work')
